import react from 'react';
import {Route, Routes, BrowserRouter} from 'react-router-dom';
import Home from './pages/home';
import Login from './pages/login';
import Register from './pages/register'
import Loggedin from './pages/loggedin';
function App() {
  return (
    <div className="App">
      
    <BrowserRouter style>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path='/register' element={<Register/>} />
        <Route path='/welcome' element={<Loggedin/>} />

      </Routes>
    </BrowserRouter>

    </div>
  );
}

export default App;
