import React, {useState, useEffect} from 'react'
import {TextField, Box, Paper, Button, Container, Typography, InputAdornment} from '@mui/material'
import { withStyles } from '@mui/styles';
import backsvg from '../blob.svg'
import {useNavigate} from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import {useDispatch, useSelector} from 'react-redux';
import {signin} from '../actions/user.js'


function Login() {
  let navigate = useNavigate();

  const initialstate = {name: '', password: ''};
  const [formData, setFormData] = useState(initialstate);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  let dispatch = useDispatch();
  const userState = useSelector(state => state.user);
  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value})
  }

  useEffect(() => {
    if(user){
      navigate('/welcome');
    }
  });


  const  handleSubmit = () => {
    if (formData.password.length < 1 || formData.name.length < 1) {
      return;
    }
    dispatch(signin({...formData})).then(e => {
      if(!e){
        console.log('error')
      }else {
        navigate('/welcome')
      }
    })
  }


    return (
        <div style={{backgroundImage: `url(${backsvg})`, height: '100%', width: '100%', backgroundSize: 'cover', position: 'fixed'}}>
          <Container maxWidth='sm' style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
            <div style={{top: '30%', position: 'absolute', rowGap: '2vh', display: 'flex', flexDirection: 'column'}}>
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Typography style={{fontWeight: 600, fontSize: '2vw'}} color={'white'} >Login</Typography>
                <Typography color={'white'} >Please enter your Username and your Password</Typography>
              </div>
                <div style={{display: 'grid', flexDirection: 'column', alignItems: 'center', rowGap: '30px', position: 'relative' }} >
                  <TextField name='name' onChange={handleChange} sx={{input: {color: 'white'}, fieldset: {borderColor: 'white'}, label: {color: '#BEBEBE'}, "&:hover": {"&& fieldset": {borderColor: '#6C6EE8'}}}} placeholder='Username' InputProps={{startAdornment: (<InputAdornment position='start' ><AccountCircleIcon style={{color:'white'}}/></InputAdornment>)}}/>
                  <TextField name='password' onChange={handleChange} type='password' sx={{input: {color: 'white'}, fieldset: {borderColor: 'white'}, label: {color: '#BEBEBE'}, "&:hover": {"&& fieldset": {borderColor: '#6C6EE8'}}}} placeholder='Password' InputProps={{startAdornment: (<InputAdornment position='start' ><LockIcon style={{color:'white'}}/></InputAdornment>)}}/>
                  <Button style={{width: '100%', position: 'relative', borderRadius: '10px'}} variant='contained' onClick={handleSubmit} >LOGIN</Button>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <Typography style={{fontSize: '18px', color: 'white'}}><i>Don't have an account? <Button onClick={() => navigate("/register")} variant='text'><b>Register!</b></Button></i></Typography>
                </div>
              </div>
            </Container>
            
        </div>
    )
}

export default Login
