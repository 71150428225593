import { Typography, Container, TextField, Button, InputAdornment, Alert, AlertTitle} from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import backsvg from '../blob.svg'
import {useDispatch, useSelector} from 'react-redux';
import {signup} from '../actions/user.js'

function Register() {
    let dispatch = useDispatch();
    const navigate = useNavigate()
    const user = localStorage.getItem('user')
    const InitalState = {name: '', password: '', confirmPassword: ''}
    const [formData, setFormData] = React.useState(InitalState)
    const [error, setError] = React.useState('')

    useEffect(() => {
      if(user){
        navigate('/welcome');
      }
    });

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }
    const handleSubmit = () => {
      if (formData.password.length < 1 || formData.name.length < 1  || formData.confirmPassword.length < 1) {
        setError('Please fill in all fields')
        return
      }
        if(formData.password !== formData.confirmPassword){
          setError('Passwords do not match')
          return;
            
        }
        dispatch(signup({name: formData.name, password: formData.password})).then(() => {
          setError('');
          navigate('/')
      });
        

    }

    return (
        <div style={{backgroundImage: `url(${backsvg})`, height: '100%', width: '100%', backgroundSize: 'cover', position: 'fixed'}}>
        <Container maxWidth='sm' style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
          <div style={{top: '20vh', position: 'absolute', rowGap: '2vh', display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <Typography style={{fontWeight: 600, fontSize: '2vw'}} color={'white'} >Register</Typography>
              <Typography color={'white'} >Please enter your details to create an account</Typography>
            </div>
              <div style={{display: 'grid', flexDirection: 'column', alignItems: 'center', rowGap: '30px', position: 'relative' }} >
                <TextField onChange={handleChange} name='name'sx={{input: {color: 'white'}, fieldset: {borderColor: 'white'}, label: {color: '#BEBEBE'}, "&:hover": {"&& fieldset": {borderColor: '#6C6EE8'}}}} placeholder='Username' InputProps={{startAdornment: (<InputAdornment position='start' ><AccountCircleIcon style={{color:'white'}}/></InputAdornment>)}}/>
                <TextField onChange={handleChange} name='password' type='password' sx={{input: {color: 'white'}, fieldset: {borderColor: 'white'}, label: {color: '#BEBEBE'}, "&:hover": {"&& fieldset": {borderColor: '#6C6EE8'}}}} placeholder='Password' InputProps={{startAdornment: (<InputAdornment position='start' ><LockIcon style={{color:'white'}}/></InputAdornment>)}} />
                <TextField onChange={handleChange} name='confirmPassword' type='password' sx={{input: {color: 'white'}, fieldset: {borderColor: 'white'}, label: {color: '#BEBEBE'}, "&:hover": {"&& fieldset": {borderColor: '#6C6EE8'}}}} placeholder='Confirm Password' InputProps={{startAdornment: (<InputAdornment position='start' ><LockIcon style={{color:'white'}}/></InputAdornment>)}}/>
                {error && <Alert severity='error'><b>{error}</b></Alert>}
                <Button onClick={handleSubmit} style={{width: '100%', position: 'relative', borderRadius: '10px'}} variant='contained' >SIGNUP</Button>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Typography style={{fontSize: '18px', color: 'white'}}><i>Already have an account? <Button onClick={() => navigate("/login")} variant='text'><b>Login!</b></Button></i></Typography>
              </div>
              
            </div>
          </Container>
          
      </div>
    )
}

export default Register
