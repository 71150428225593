import * as api from '../api';
import { useNavigate} from 'react-router-dom';
export const signup = (newUser) => async (dispatch) => {
    try {
        const {data} = await api.signup(newUser);
        dispatch({type: 'SIGNUP', data});
    } catch (error) {
        console.log(error.message);
    }
};

export const signin = (user) => async (dispatch) => {
    
    try {
        const {data} = await api.signin(user);
        dispatch({type: 'SIGNIN', data});
        return data;
    } catch (error) {
        console.log(error.message);
        return
    }
}

export const logout = () => async (dispatch) => {
    try {
        dispatch({type: 'LOGOUT'});
    } catch (error) {
        console.log(error.message);
    }
}