import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider, provider} from 'react-redux';
import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';

const store = createStore(reducers, compose(applyMiddleware(thunk)))


ReactDOM.render(
 
  <React.StrictMode>
     <Provider store={store}>
    <style jsx global>{`
      body {
        margin: 0px;
        padding: 0px;
      }
    `}</style>
    <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);


