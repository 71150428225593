import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import LogoutBtn from '../components/LogoutButton'


function Loggedin() {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const navigate = useNavigate()
    

    useEffect(() => {
        if(!user) {
            navigate('/');
        }
        console.log(user)
    })
    
    return (
        <div>
            <Typography>Welcome: {user?.name}</Typography>
            <Typography>You're password is: {user?.password}</Typography>
            <LogoutBtn />
        </div>
    )
}

export default Loggedin
