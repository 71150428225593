import React, {useState} from 'react'
import {Button, Container, Paper, Typography} from '@mui/material'
import { useNavigate} from 'react-router-dom';
const Home = () => {
    let navigate = useNavigate();
    const handleClick = () => {
        navigate('/login');
    }
    return (
        <div>
                <Container maxWidth='sm' style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', top: '40%', position: 'absolute'}} >
                        <Typography fontSize={30} >Welcome, please sign in to use the website</ Typography>
                        <Button onClick={handleClick} style={{fontSize: '30px'}} size='large'>Login</Button>
                    </div>
            </Container>
            
            
        </div>
    )
}

export default Home
