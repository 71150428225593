import { Button } from '@mui/material'
import React, {useState} from 'react'
import { logout } from '../actions/user';
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom';
function LogoutButton() {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const handleClick = () => {
        dispatch(logout());
        navigate('/login');

    }
    return (
        <div>
            <Button onClick={handleClick}>Logout</Button>
        </div>
    )
}

export default LogoutButton
